{********************************************************************}
{                                                                    }
{ written by TMS Software                                            }
{            copyright (c) 2019 - 2023                               }
{            Email : info@tmssoftware.com                            }
{            Web : http://www.tmssoftware.com                        }
{                                                                    }
{ The source code is given as is. The author is not responsible      }
{ for any possible damage done due to the use of this code.          }
{ The complete source code remains property of the author and may    }
{ not be distributed, published, given or sold in any form as such.  }
{ No parts of the source code can be included in any other component }
{ or application without written authorization of the author.        }
{********************************************************************}

unit WEBLib.Sentry;

{$DEFINE NOPP}

interface

uses
  Classes, SysUtils, Web, WebLib.Controls, js, libsentry;


type
  TSentry = class(TComponent)
  private
    fEnabled: Boolean;
    fDSN, fRelease: String;

    fInitDone: Boolean;
  protected
    procedure AfterLoadDFMValues; override;
  public
    constructor Create(AOwner: TComponent); override;
    procedure Init;
    procedure CaptureMessage(aMsg: String);
    procedure CaptureException(anObj: TJSObject; remark: String='');
    procedure SetUser(aName: String);
    procedure setTag(aKey: String; aValue: String);
    procedure AddBreadcrumb(aCategory: String; aMessage: String);
  published
    property Enabled: Boolean read fEnabled write fEnabled;
    property DSN: String read fDSN write fDSN;
    property Release: String read fRelease write fRelease;
  end;

  TWebSentry = class(TSentry);

implementation

procedure TSentry.AfterLoadDFMValues;
begin
  inherited;
  Init;
end;

constructor TSentry.Create(AOwner: TComponent);
begin
  inherited;
  fDSN := '';
  fRelease := '1.0';
  fEnabled := True;
end;

// Next to do: Also do same thing on CaptureException
procedure TSentry.Init;
var
  parameters: TJSObject;
  frames: TJSArray;
begin
  if (fDSN = '') or (fRelease = '') then
    exit;
  parameters := New([
            'dsn', DSN,
            'release', Release,
            'attachStacktrace', True,

            'beforeSend',
               { Sentry allows us to inspect the event being sent and change things.
                 We use this opportunity to identify Delphi exceptions and then rather
                 send the original JavaScript error now remembered in Delphi Exception
                 object. This gives a better stack trace under all conditions. Further
                 we also need to fix this stack trace elsewhere in this component
                 because it also contains the Exception class code which we don't want.
               }
               function(event: TBeforeSendEvent; hint: TBeforeSendHint): JSValue
               var
                 exceptionValue: TBeforeSendEventExceptionValue;
                 isUncaughtDelphiException: Boolean;
                 extraData, lastStackItem: TJSObject;
               begin
                 isUncaughtDelphiException := False;

                 // preserve extradata coming in event as we
                 // will add flags to it
                 if event.hasOwnProperty('extra') then
                 begin
                    extraData := TJSObject(event['extra']);
                    // Further the original Delphi object is
                    // sent as __serialized__ property which
                    // we don't want now, increases size.
                    JSDelete(extraData, '__serialized__');
                 end
                 else
                    extraData := TJSObject.New;

                 // hint.originalException may be a JS Error Object or a Delphi
                 // error object. So we identify it.
                 if Assigned(hint.originalException) and
                    hint.originalException.hasOwnProperty('FHelpContext')
                 then
                 begin
                   // Identified that this is a Delphi object

                   // flag sent as additional data, just for information
                   isUncaughtDelphiException := not hint.hasOwnProperty('syntheticException');

                   // if sending to Sentry is enabled
                   if Enabled then
                   begin
                     // This flag allows us to identify and modify the
                     // stack to remove Delphi Exception class code from
                     // the top of the stack elsewhere in this component.
                     extraData['FromDelphi'] := 'yes';

                     // The following is sent for information only to be recorded
                     // in the issue
                     extraData['UncaughtException'] := isUncaughtDelphiException;

                     // hint.originalException is Delphi Exception object so we
                     // do not send it. Instead, we call captureException with the
                     // original JS Error Object remembered in Delphi Exception object.
                     TJSSentry.captureException(
                                  TJSObject(hint.originalException['FJSError']),
                                  New([
                                        'extra', extraData
                                       ]));
                   end;

                   // We do not send the Delphi object because we have already called
                   // captureException with the internal JS Error object now.
                   Result := nil;
                   exit;
                 end;

                 { This code gets control on a reentry after CaptureException above.
                   But since this can also come in case of normal JS errors here,
                   we need to identify it to be coming from captureException (Delphi case)
                   above so that we can modify the stack to remove the top 2 items that
                   contain the Exception class code because we want the stack to start
                   from the real error code.
                 }
                 if event.hasOwnProperty('extra') and
                     TJSObject(event['extra']).hasOwnProperty('FromDelphi') then
                 begin
                   // Identified as JS Error from Delphi Exception.
                   // Let's modify the stack as described above.
                   if Assigned(event.exception) then
                     exceptionValue := TBeforeSendEventExceptionValue(event.exception.FValues[0])
                   else
                     exceptionValue := nil;
                   if Assigned(exceptionValue) and Assigned(exceptionValue.stacktrace) and
                     exceptionValue.stacktrace.hasOwnProperty('frames') then
                     frames := exceptionValue.stacktrace.frames
                   else
                     frames := nil;
                   if Assigned(frames) and (frames.length > 2) then
                   begin
                     frames.pop;
                     frames.pop;
                   end;
                 end;

                 // Similarly, Capture Message case has an extra
                 // stack line containing TSentry's oen method
                 // CaptureMessage. Remove it for proper stack display.
                 if event.hasOwnProperty('message') and event.hasOwnProperty('stacktrace') then
                 begin
                   if event.stacktrace.hasOwnProperty('frames') then
                   begin
                     frames := event.stacktrace.frames;
                     if Assigned(frames) and (frames.length > 2) then
                     begin
                       // make sure that it is indeed the stack item
                       // that we want to remove
                       lastStackItem := TJSObject(frames[frames.length-1]);
                       if (String(lastStackItem['function']) = 'Object.CaptureMessage') then
                         frames.pop;
                     end;
                   end;
                 end;

                 // Is sending to Sentry enabled?
                 if Enabled then
                 begin
                   Result := event;
                 end
                 else
                   Result := nil;
               end
            ]);

  TJSSentry.Init(parameters);
  fInitDone := True;
end;

procedure TSentry.CaptureMessage(aMsg: String);
begin
  if not fInitDone then
    exit;
  TJSSentry.captureMessage(aMsg);
end;

procedure TSentry.CaptureException(anObj: TJSObject; remark: String='');
begin
  if not fInitDone then
    exit;
  if remark = '' then
    TJSSentry.captureException(anObj)
  else
  begin
    TJSSentry.captureException(anObj,
                               New([
                                    'extra',
                                     New([
                                          'Remark', remark
                                         ])

                                   ])
                               );
  end;
end;

procedure TSentry.SetUser(aName: String);
var
  passName: JSValue;
begin
  if not fInitDone then
    exit;
  passName := aName;
  if passName = '' then
    passName := nil;
  TJSSentry.configureScope(
          procedure(scope: TJSSentryScope)
          begin
            scope.setUser(
                New([
                'username', passName
                ]));
          end
    );
end;

procedure TSentry.SetTag(aKey: String; aValue: String);
begin
  if not fInitDone then
    exit;
  TJSSentry.configureScope(
          procedure(scope: TJSSentryScope)
          begin
            scope.setTag(aKey, aValue);
          end
    );
end;

procedure TSentry.AddBreadcrumb(aCategory: String; aMessage: String);
begin
  if not fInitDone then
    exit;
  TJSSentry.addBreadcrumb(
        New([
            'category', aCategory,
            'message', aMessage
            ]));
end;

end.
