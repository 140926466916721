unit USentry;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Vcl.Controls, Vcl.StdCtrls, WEBLib.StdCtrls,
  WEBLib.Sentry, WEBLib.ExtCtrls;

type
  TForm1 = class(TForm)
    btRaiseJSError: TButton;
    WebSentry1: TSentry;
    btSendDelphiError: TButton;
    ckEnableSentry: TCheckBox;
    btSendJSError: TButton;
    WebLabel1: TLabel;
    edRemark: TEdit;
    WebLabel2: TLabel;
    WebLabel3: TLabel;
    btRaiseDelphiError: TButton;
    WebLabel4: TLabel;
    btSendMessage: TButton;
    WebPanel1: TPanel;
    WebPanel2: TPanel;
    WebPanel3: TPanel;
    WebLabel5: TLabel;
    edMessage: TEdit;
    WebLabel6: TLabel;
    WebLabel7: TLabel;
    procedure WebFormCreate(Sender: TObject);
    procedure btSendDelphiErrorClick(Sender: TObject);
    procedure ckEnableSentryClick(Sender: TObject);
    procedure btSendJSErrorClick(Sender: TObject);
    procedure btRaiseJSErrorClick(Sender: TObject);
    procedure btRaiseDelphiErrorClick(Sender: TObject);
    procedure btSendMessageClick(Sender: TObject);
  private
    { Private declarations }
  public
    { Public declarations }
  protected procedure LoadDFMValues; override; end;

var
  Form1: TForm1;

implementation

{$R *.dfm}

const
WEB_SENTRY_DSN = 'https://e57de7e84cce44d89c4fe356cc2341e0@o4504537489473536.ingest.sentry.io/4504537492946944';

procedure TForm1.WebFormCreate(Sender: TObject);
begin
//  WebSentry1.DSN := 'https://de6122fc87ad4350befe14a7a0f861a8@o360510.ingest.sentry.io/5272382';

  // set your Sentry DSN here
  WebSentry1.DSN := WEB_SENTRY_DSN;//'https://9fd0f74eb07c4ee8a9490f2dd695fd04@o425384.ingest.sentry.io/5360544';
  WebSentry1.Release := 'DemoSentry_SMXi@2.0';
  WebSentry1.Init;
  WebSentry1.SetUser('Russell_Web');
end;

procedure TForm1.ckEnableSentryClick(Sender: TObject);
begin
  WebSentry1.Enabled := ckEnableSentry.Checked;
end;

procedure TForm1.btSendDelphiErrorClick(Sender: TObject);
var
  sendException: TJSObject;
begin
  try
    raise Exception.Create('Delphi Error detected');
  except
    On E: Exception do
    begin
      sendException := TJSObject(E);
    end;
  end;

  WebSentry1.CaptureException(sendException, edRemark.Text);

end;

procedure TForm1.btSendJSErrorClick(Sender: TObject);
var
  sendException: TJSObject;
begin
  asm
    try {
      throw new Error('JS Error detected');
    }
    catch(err) {
      sendException = err;
    }
  end;
  WebSentry1.CaptureException(sendException, edRemark.Text);
end;

procedure TForm1.btRaiseDelphiErrorClick(Sender: TObject);
begin
   raise Exception.Create('Unexpected Delphi Error (uncaught)')
end;

procedure TForm1.btRaiseJSErrorClick(Sender: TObject);
begin
  asm
    throw new Error('Unexpected JS Error (uncaught)');
  end;
end;

procedure TForm1.btSendMessageClick(Sender: TObject);
var
  aMessage: String;
begin
  aMessage := Trim(edMessage.Text);
  if aMessage <> '' then
    WebSentry1.CaptureMessage(aMessage);
end;


procedure TForm1.LoadDFMValues;
begin
  inherited LoadDFMValues;

  WebLabel6 := TLabel.Create('title');
  WebLabel7 := TLabel.Create('description');
  ckEnableSentry := TCheckBox.Create(Self);
  WebPanel1 := TPanel.Create(Self);
  WebLabel1 := TLabel.Create(Self);
  WebLabel2 := TLabel.Create(Self);
  btSendDelphiError := TButton.Create(Self);
  btSendJSError := TButton.Create(Self);
  edRemark := TEdit.Create(Self);
  WebPanel2 := TPanel.Create(Self);
  WebLabel3 := TLabel.Create(Self);
  btRaiseJSError := TButton.Create(Self);
  btRaiseDelphiError := TButton.Create(Self);
  WebPanel3 := TPanel.Create(Self);
  WebLabel4 := TLabel.Create(Self);
  WebLabel5 := TLabel.Create(Self);
  btSendMessage := TButton.Create(Self);
  edMessage := TEdit.Create(Self);
  WebSentry1 := TSentry.Create(Self);

  WebLabel6.BeforeLoadDFMValues;
  WebLabel7.BeforeLoadDFMValues;
  ckEnableSentry.BeforeLoadDFMValues;
  WebPanel1.BeforeLoadDFMValues;
  WebLabel1.BeforeLoadDFMValues;
  WebLabel2.BeforeLoadDFMValues;
  btSendDelphiError.BeforeLoadDFMValues;
  btSendJSError.BeforeLoadDFMValues;
  edRemark.BeforeLoadDFMValues;
  WebPanel2.BeforeLoadDFMValues;
  WebLabel3.BeforeLoadDFMValues;
  btRaiseJSError.BeforeLoadDFMValues;
  btRaiseDelphiError.BeforeLoadDFMValues;
  WebPanel3.BeforeLoadDFMValues;
  WebLabel4.BeforeLoadDFMValues;
  WebLabel5.BeforeLoadDFMValues;
  btSendMessage.BeforeLoadDFMValues;
  edMessage.BeforeLoadDFMValues;
  WebSentry1.BeforeLoadDFMValues;
  try
    Name := 'Form1';
    Width := 764;
    Height := 480;
    Caption := ',';
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -16;
    Font.Name := 'Tahoma';
    Font.Style := [];
    FormContainer := 'appcontent';
    ParentFont := False;
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    WebLabel6.SetParentComponent(Self);
    WebLabel6.Name := 'WebLabel6';
    WebLabel6.Left := 24;
    WebLabel6.Top := 429;
    WebLabel6.Width := 188;
    WebLabel6.Height := 19;
    WebLabel6.Caption := 'Sentry error logging demo';
    WebLabel6.HeightPercent := 100.000000000000000000;
    WebLabel6.WidthPercent := 100.000000000000000000;
    WebLabel7.SetParentComponent(Self);
    WebLabel7.Name := 'WebLabel7';
    WebLabel7.Left := 24;
    WebLabel7.Top := 453;
    WebLabel7.Width := 487;
    WebLabel7.Height := 19;
    WebLabel7.Caption := 'Logs JavaScript errors and Object Pascal exceptions to Sentry service';
    WebLabel7.HeightPercent := 100.000000000000000000;
    WebLabel7.WidthPercent := 100.000000000000000000;
    ckEnableSentry.SetParentComponent(Self);
    ckEnableSentry.Name := 'ckEnableSentry';
    ckEnableSentry.Left := 16;
    ckEnableSentry.Top := -1;
    ckEnableSentry.Width := 225;
    ckEnableSentry.Height := 22;
    ckEnableSentry.Caption := 'Send to Sentry Enabled';
    ckEnableSentry.Checked := True;
    ckEnableSentry.ChildOrder := 3;
    ckEnableSentry.Color := clNone;
    ckEnableSentry.HeightPercent := 100.000000000000000000;
    ckEnableSentry.State := cbChecked;
    ckEnableSentry.WidthPercent := 100.000000000000000000;
    SetEvent(ckEnableSentry, Self, 'OnClick', 'ckEnableSentryClick');
    WebPanel1.SetParentComponent(Self);
    WebPanel1.Name := 'WebPanel1';
    WebPanel1.Left := 8;
    WebPanel1.Top := 27;
    WebPanel1.Width := 756;
    WebPanel1.Height := 137;
    WebPanel1.ChildOrder := 12;
    WebLabel1.SetParentComponent(WebPanel1);
    WebLabel1.Name := 'WebLabel1';
    WebLabel1.Left := 16;
    WebLabel1.Top := 11;
    WebLabel1.Width := 425;
    WebLabel1.Height := 19;
    WebLabel1.Caption := 'Code sample: Send anticipated (caught) errors to Sentry log';
    WebLabel1.HeightPercent := 100.000000000000000000;
    WebLabel1.WidthPercent := 100.000000000000000000;
    WebLabel2.SetParentComponent(WebPanel1);
    WebLabel2.Name := 'WebLabel2';
    WebLabel2.Left := 389;
    WebLabel2.Top := 58;
    WebLabel2.Width := 322;
    WebLabel2.Height := 19;
    WebLabel2.Caption := 'Remark (optional) appears as Additional Data';
    WebLabel2.HeightPercent := 100.000000000000000000;
    WebLabel2.WidthPercent := 100.000000000000000000;
    btSendDelphiError.SetParentComponent(WebPanel1);
    btSendDelphiError.Name := 'btSendDelphiError';
    btSendDelphiError.Left := 16;
    btSendDelphiError.Top := 36;
    btSendDelphiError.Width := 353;
    btSendDelphiError.Height := 41;
    btSendDelphiError.Caption := 'Catch Delphi Error and Send to Sentry';
    btSendDelphiError.ChildOrder := 1;
    btSendDelphiError.HeightPercent := 100.000000000000000000;
    btSendDelphiError.WidthPercent := 100.000000000000000000;
    SetEvent(btSendDelphiError, Self, 'OnClick', 'btSendDelphiErrorClick');
    btSendJSError.SetParentComponent(WebPanel1);
    btSendJSError.Name := 'btSendJSError';
    btSendJSError.Left := 16;
    btSendJSError.Top := 83;
    btSendJSError.Width := 353;
    btSendJSError.Height := 41;
    btSendJSError.Caption := 'Catch JS Error and Send to Sentry';
    btSendJSError.ChildOrder := 1;
    btSendJSError.HeightPercent := 100.000000000000000000;
    btSendJSError.WidthPercent := 100.000000000000000000;
    SetEvent(btSendJSError, Self, 'OnClick', 'btSendJSErrorClick');
    edRemark.SetParentComponent(WebPanel1);
    edRemark.Name := 'edRemark';
    edRemark.Left := 389;
    edRemark.Top := 83;
    edRemark.Width := 337;
    edRemark.Height := 22;
    edRemark.ChildOrder := 2;
    edRemark.HeightPercent := 100.000000000000000000;
    edRemark.WidthPercent := 100.000000000000000000;
    WebPanel2.SetParentComponent(Self);
    WebPanel2.Name := 'WebPanel2';
    WebPanel2.Left := 8;
    WebPanel2.Top := 180;
    WebPanel2.Width := 756;
    WebPanel2.Height := 125;
    WebPanel2.ChildOrder := 8;
    WebLabel3.SetParentComponent(WebPanel2);
    WebLabel3.Name := 'WebLabel3';
    WebLabel3.Left := 16;
    WebLabel3.Top := 3;
    WebLabel3.Width := 574;
    WebLabel3.Height := 19;
    WebLabel3.Caption := 'Simulate unexpected (uncaught) errors to demonstrate "Auto Send" to Sentry log';
    WebLabel3.HeightPercent := 100.000000000000000000;
    WebLabel3.WidthPercent := 100.000000000000000000;
    btRaiseJSError.SetParentComponent(WebPanel2);
    btRaiseJSError.Name := 'btRaiseJSError';
    btRaiseJSError.Left := 16;
    btRaiseJSError.Top := 75;
    btRaiseJSError.Width := 274;
    btRaiseJSError.Height := 41;
    btRaiseJSError.Caption := 'Throw JS Error Uncaught';
    btRaiseJSError.HeightPercent := 100.000000000000000000;
    btRaiseJSError.WidthPercent := 100.000000000000000000;
    SetEvent(btRaiseJSError, Self, 'OnClick', 'btRaiseJSErrorClick');
    btRaiseDelphiError.SetParentComponent(WebPanel2);
    btRaiseDelphiError.Name := 'btRaiseDelphiError';
    btRaiseDelphiError.Left := 16;
    btRaiseDelphiError.Top := 28;
    btRaiseDelphiError.Width := 274;
    btRaiseDelphiError.Height := 41;
    btRaiseDelphiError.Caption := 'Raise Delphi Exception Uncaught';
    btRaiseDelphiError.ChildOrder := 1;
    btRaiseDelphiError.HeightPercent := 100.000000000000000000;
    btRaiseDelphiError.WidthPercent := 100.000000000000000000;
    SetEvent(btRaiseDelphiError, Self, 'OnClick', 'btRaiseDelphiErrorClick');
    WebPanel3.SetParentComponent(Self);
    WebPanel3.Name := 'WebPanel3';
    WebPanel3.Left := 8;
    WebPanel3.Top := 319;
    WebPanel3.Width := 756;
    WebPanel3.Height := 104;
    WebPanel3.ChildOrder := 6;
    WebLabel4.SetParentComponent(WebPanel3);
    WebLabel4.Name := 'WebLabel4';
    WebLabel4.Left := 16;
    WebLabel4.Top := 3;
    WebLabel4.Width := 312;
    WebLabel4.Height := 19;
    WebLabel4.Caption := 'Code sample: Send a message to Sentry log';
    WebLabel4.HeightPercent := 100.000000000000000000;
    WebLabel4.WidthPercent := 100.000000000000000000;
    WebLabel5.SetParentComponent(WebPanel3);
    WebLabel5.Name := 'WebLabel5';
    WebLabel5.Left := 365;
    WebLabel5.Top := 18;
    WebLabel5.Width := 59;
    WebLabel5.Height := 19;
    WebLabel5.Caption := 'Message';
    WebLabel5.HeightPercent := 100.000000000000000000;
    WebLabel5.WidthPercent := 100.000000000000000000;
    btSendMessage.SetParentComponent(WebPanel3);
    btSendMessage.Name := 'btSendMessage';
    btSendMessage.Left := 16;
    btSendMessage.Top := 28;
    btSendMessage.Width := 274;
    btSendMessage.Height := 41;
    btSendMessage.Caption := 'Send Log Message to Sentry';
    btSendMessage.HeightPercent := 100.000000000000000000;
    btSendMessage.WidthPercent := 100.000000000000000000;
    SetEvent(btSendMessage, Self, 'OnClick', 'btSendMessageClick');
    edMessage.SetParentComponent(WebPanel3);
    edMessage.Name := 'edMessage';
    edMessage.Left := 365;
    edMessage.Top := 43;
    edMessage.Width := 337;
    edMessage.Height := 22;
    edMessage.ChildOrder := 2;
    edMessage.HeightPercent := 100.000000000000000000;
    edMessage.Text := 'Hello from TMS Web Core';
    edMessage.TextHint := 'A remark';
    edMessage.WidthPercent := 100.000000000000000000;
    WebSentry1.SetParentComponent(Self);
    WebSentry1.Name := 'WebSentry1';
    WebSentry1.Enabled := True;
    WebSentry1.Release := '1.0';
    WebSentry1.Left := 672;
    WebSentry1.Top := 240;
  finally
    WebLabel6.AfterLoadDFMValues;
    WebLabel7.AfterLoadDFMValues;
    ckEnableSentry.AfterLoadDFMValues;
    WebPanel1.AfterLoadDFMValues;
    WebLabel1.AfterLoadDFMValues;
    WebLabel2.AfterLoadDFMValues;
    btSendDelphiError.AfterLoadDFMValues;
    btSendJSError.AfterLoadDFMValues;
    edRemark.AfterLoadDFMValues;
    WebPanel2.AfterLoadDFMValues;
    WebLabel3.AfterLoadDFMValues;
    btRaiseJSError.AfterLoadDFMValues;
    btRaiseDelphiError.AfterLoadDFMValues;
    WebPanel3.AfterLoadDFMValues;
    WebLabel4.AfterLoadDFMValues;
    WebLabel5.AfterLoadDFMValues;
    btSendMessage.AfterLoadDFMValues;
    edMessage.AfterLoadDFMValues;
    WebSentry1.AfterLoadDFMValues;
  end;
end;

end.
